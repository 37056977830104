import React from 'react'
import { Container,Nav,Navbar,Row,Col } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import Marquee from "react-fast-marquee";
const AppBar = () => {
  return (
    <>
    <>
    <div className='mark-bg'>
      <div className='d-lg-block d-none'>
        <Container fluid>
              <Row className='py-2'>
              <Col lg="4" xs="4" sm="4" className='align-self-center d-lg-block d-none'>
                  <div className='d-flex align-items-center'>
                    <div>
                      <img src={require('../../assets/images/gpay.png')} className='img-fluid gpay' alt='gpay' />
                    </div>
                    <div className='text-white'>
                       <h5 className='bold mt-2 mx-2'>9003885080</h5> 
                    </div>
                  </div>
              </Col>
                <Col lg='8' className='align-self-center text-white'>
                  <Marquee pauseOnHover={true}  direction="left" speed={50} delay={9}>
                    |  Whatsapp : +91  90038 85080 | Mobile No : +91 99447 26077 | Email :  jcpyromart@gmail.com | Email : umeshujessh@gmail.com
                  </Marquee>
                </Col>
              </Row>
          </Container>
      </div>
      <div className='d-lg-none d-block py-3'>
          <Container fluid>
            <Row>
              <Col xs="4" sm="4">
              <a href="https://wa.me/919003885080?text=Hello JC Pyro Mart">
                <div className='mob-icon mx-auto'>
                    <img src={require('../../assets/images/whatsappicon.webp')} className='img-fluid' alt='product name' />
                  </div>
              </a>
                  
              </Col>
              <Col xs="4" sm="4">
              <a href='tel:+919003885080'>
                <div className='mob-icon mx-auto'>
                    <img src={require('../../assets/images/callicon.webp')} className='img-fluid' alt='product name' />
                  </div> 
              </a>
                 
              </Col>
              <Col xs="4" sm="4">
              <a href='https://maps.app.goo.gl/Kgs3nH8Msh8xNFBo8' alt="">
                <div className='mob-icon mx-auto'>
                    <img src={require('../../assets/images/googlelocation.png')} className='img-fluid' alt='product name' />
                  </div> 
              </a>
              </Col>
            </Row>
          </Container>
      </div>
    </div>
    </>
   
    <>
      <Navbar expand="lg" className=" headnav" >
        <Container fluid className='px-lg-5'>
          <Navbar.Brand href="/">
            <div className='logo mx-auto'>
              <img src={require('../../assets/images/storelogo.png')} className='img-fluid' alt='product name' />
            </div>
            </Navbar.Brand>
        <div className='mx-auto'>
          <Navbar.Toggle aria-controls="basic-navbar-nav mx-auto text-center py-3" />
        </div>
         
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto text-center py-3">
              <Nav.Link as={NavLink} exact to={"/"} >Home</Nav.Link>
              <Nav.Link as={NavLink} exact to={"/about"} >About Us</Nav.Link>
              <Nav.Link as={NavLink} exact to={"/products"} >Pricelist</Nav.Link>
              <Nav.Link as={NavLink} exact to={"/safetytips"} >Safety Tips</Nav.Link>
              <Nav.Link as={NavLink} exact to={"/contact"} >Contact Us</Nav.Link>
              
            </Nav>
            <Nav.Link as={NavLink} exact to={"/products"} className='s-btn'>Shop Now</Nav.Link>
                 </Navbar.Collapse>
        </Container>
      </Navbar>
    </>

    </>
  )
}

export default AppBar